@import "~intl-tel-input/build/css/intlTelInput.css";
@import 'ngx-toastr/toastr';
:root {
  --primary_dark: #219572;
  --primary: #00ab78;
  --primary_10: #00ab781a;
  --primary_30: #00ab784d;
  --primary_50: #00ab7880;
  --primary_60: #00ab7899;
  --primary_70: #00ab78b3;
  --primary_80: #00ad79cc;
  --primary_90: #00ad79e6;;
  --primary_2: #016749;
  --primary_30: #00ab784d;
  --secondary: #058dd9;
  --background_1: #262B2F;
  --background_2: #333A3F;
  --background_3: #1B1E22;
  --background_3_60: #1b1e22bf;
  --background_hover_primary: #d7fdeabd;
  --light: #ecf0f1;
  --blue: #058dd9;
  --danger: #e74c3c;
  --danger_90: #e74d3ce6;
  --danger_70: #e74d3cb3;
  --danger_60: #e74d3c99;
  --danger_10: #e74c3c1a;
  --danger-light: #ffd9db;
  --danger-dark: #a76566;
  --green: #00ab78;
  --yellow: #fbc303;
  --orange: #ffa14a;
  --muted: #777777;
  --active: #00ab78;
  --button_color: #00ab78;
  --price_color: #fbc303;
  --border_color: #e7e7e7;
  --block: #e7e7e7;
  --icon: #4a4a4a;
  --gray: #67757c;
  --inactive: #4f7da8;
  --warning: #ffb22b;
  --warning_30: #ffb22b4d;
  --warning_40: #ffb22b66;
  --border-gray: #00000026;
  --row-hover: #bbbbbb26;
  --youtube: #FF0000;
  --box_shadow: 4px 4px 9px rgba(64, 64, 64, 0.56);
  --instagram: radial-gradient(circle, rgba(252,175,69,1) 0%, rgba(253,29,29,1) 25%, rgba(131,58,180,1) 50%, rgba(253,29,29,1) 75%, rgba(252,175,69,1) 100%);
}

.swal2-confirm {
  background-color: var(--primary) !important;
  color: white !important;
}

.swal2-complete {
  background-color: var(--secondary) !important;
  color: white !important;
}

.swal2-confirm-delete {
  background-color: var(--danger) !important;
  color: white !important;
}

.swal2-cancel {
  background-color: var(--gray) !important;
  color: white !important;
}

.swal-title-custom {
  font-weight: bold; /* Para hacer el título en negrita */
  color: red; /* Cambia esto al color que desees o utiliza textColor directamente */
  font-size: 18px; /* Tamaño de la fuente */
}

div{
  font-weight: 400;
}

button {
  outline: none !important;
}

.no-select {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Versión estándar, funciona en la mayoría de los navegadores actuales */
}

.txt_title_form {
  font-weight: 500;
  margin-bottom: 20px;
  color: #2c3e50;
  font-size: large;
}

.btn-circle {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.config_container {
  display: flex;
  flex-direction: column;
}

.container-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.title-desktop {
  color: #00ab78;
  font-weight: 500;
  text-align: left;
  margin-bottom: 1rem;
}

.swal2-styled.swal2-confirm.swalBtnColor {
  color: #a5dc86;
}

.box__error__item{
  color: #000;

}
.list__selector__item{
  color: #000;
  cursor: pointer;
}

.config_update{
  cursor: pointer;
  font-weight: 500;
  margin-left: 5px;
  color: #00ab78;
}

.config_update:hover{
  text-decoration: underline;
}



.text_bold {
  font-weight: bold !important;
}

.txt_bold {
  font-weight: bold !important;
}

.text_default{
  color: #00ab78;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text_defaultnm{
  color: #00ab78;
}

.btn_default{
  background-color: #00ab78;
  color: #fff;
}

.btn_secundary {
  border: 1px solid var(--primary);
  color: var(--primary);
  background-color: #ffffff;
}

.btn_secundary:hover {
  border: 1px solid var(--primary);
  background: var(--primary);
  color: #ffffff;
}
/* You can add global styles to this file, and also import other style files */
.fast {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

#themecolors .selector {
  cursor: pointer;
}

.selector {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@media (min-width: 1600px) {
  .col-xlg-1,
  .col-xlg-10,
  .col-xlg-11,
  .col-xlg-12,
  .col-xlg-2,
  .col-xlg-3,
  .col-xlg-4,
  .col-xlg-5,
  .col-xlg-6,
  .col-xlg-7,
  .col-xlg-8,
  .col-xlg-9 {
    float: left;
  }
  .col-xlg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xlg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66666667%;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xlg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333333%;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xlg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xlg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66666667%;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xlg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333333%;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xlg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xlg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66666667%;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xlg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333333%;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xlg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xlg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66666667%;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xlg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333333%;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xlg-pull-12 {
    right: 100%;
  }
  .col-xlg-pull-11 {
    right: 91.66666667%;
  }
  .col-xlg-pull-10 {
    right: 83.33333333%;
  }
  .col-xlg-pull-9 {
    right: 75%;
  }
  .col-xlg-pull-8 {
    right: 66.66666667%;
  }
  .col-xlg-pull-7 {
    right: 58.33333333%;
  }
  .col-xlg-pull-6 {
    right: 50%;
  }
  .col-xlg-pull-5 {
    right: 41.66666667%;
  }
  .col-xlg-pull-4 {
    right: 33.33333333%;
  }
  .col-xlg-pull-3 {
    right: 25%;
  }
  .col-xlg-pull-2 {
    right: 16.66666667%;
  }
  .col-xlg-pull-1 {
    right: 8.33333333%;
  }
  .col-xlg-pull-0 {
    right: auto;
  }
  .col-xlg-push-12 {
    left: 100%;
  }
  .col-xlg-push-11 {
    left: 91.66666667%;
  }
  .col-xlg-push-10 {
    left: 83.33333333%;
  }
  .col-xlg-push-9 {
    left: 75%;
  }
  .col-xlg-push-8 {
    left: 66.66666667%;
  }
  .col-xlg-push-7 {
    left: 58.33333333%;
  }
  .col-xlg-push-6 {
    left: 50%;
  }
  .col-xlg-push-5 {
    left: 41.66666667%;
  }
  .col-xlg-push-4 {
    left: 33.33333333%;
  }
  .col-xlg-push-3 {
    left: 25%;
  }
  .col-xlg-push-2 {
    left: 16.66666667%;
  }
  .col-xlg-push-1 {
    left: 8.33333333%;
  }
  .col-xlg-push-0 {
    left: auto;
  }
  .offset-xlg-12 {
    margin-left: 100%;
  }
  .offset-xlg-11 {
    margin-left: 91.66666667%;
  }
  .offset-xlg-10 {
    margin-left: 83.33333333%;
  }
  .offset-xlg-9 {
    margin-left: 75%;
  }
  .offset-xlg-8 {
    margin-left: 66.66666667%;
  }
  .offset-xlg-7 {
    margin-left: 58.33333333%;
  }
  .offset-xlg-6 {
    margin-left: 50%;
  }
  .offset-xlg-5 {
    margin-left: 41.66666667%;
  }
  .offset-xlg-4 {
    margin-left: 33.33333333%;
  }
  .offset-xlg-3 {
    margin-left: 25%;
  }
  .offset-xlg-2 {
    margin-left: 16.66666667%;
  }
  .offset-xlg-1 {
    margin-left: 8.33333333%;
  }
  .offset-xlg-0 {
    margin-left: 0;
  }
}

.col-xlg-1,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*==============================================================
 For Laptop & above all (1650px)
 ============================================================== */
/*==============================================================
 For Laptop & above all (1370px)
 ============================================================== */
/*-- ==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */
@media (min-width: 1024px) {
  .testimonial-text {
    width: 80%;
  }
}

/*-- ==============================================================
 Ipad & above all(768px)
 ============================================================== */
@media (min-width: 768px) {
  .dual-bg {
    background: #474d5d;
    /* Old browsers */
    background: -moz-linear-gradient(left, #474d5d 0%, #383c49 50%, #474d5d 50%, #383c49 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #474d5d 0%, #383c49 50%, #474d5d 50%, #383c49 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#474d5d),
      color-stop(50%, #383c49),
      color-stop(50%, #474d5d),
      to(#383c49)
    );
    background: -o-linear-gradient(left, #474d5d 0%, #383c49 50%, #474d5d 50%, #383c49 100%);
    background: linear-gradient(to right, #474d5d 0%, #383c49 50%, #474d5d 50%, #383c49 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#474d5d', endColorstr='#383c49', GradientType=1);
    /* IE6-9 */
  }
}

/*-- ==============================================================
 Phone and below ipad(767px)
 ============================================================== */
@media (max-width: 767px) {
  .topheader .stylish-nav {
    padding: 20px;
    margin-top: 15px;
    border: 1px solid rgba(120, 130, 140, 0.13);
  }
  .topheader .stylish-nav .nav-link {
    padding: 0.5rem;
  }
  .banner-text {
    text-align: center;
  }
  .banner-text h1 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
  }
  .banner-text .display-7 {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
  }
}

.danger {
  color: red;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Estilos barra (thumb) de scroll */

::-webkit-scrollbar-thumb {
  background: #606060;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:active {
  background-color: #606060;
}

::-webkit-scrollbar-thumb:hover {
  background: #606060;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.btn-flotantePrincipal {
  font-size: 20px;
  /* Cambiar el tamaño de la tipografia */
  text-transform: uppercase;
  /* Texto en mayusculas */
  font-weight: bold;
  /* Fuente en negrita o bold */
  color: #ffffff;
  /* Color del texto */
  border-radius: 150px;
  /* Borde del boton */
  background-color: #1ee98a;
  /* Color de fondo */
  padding: 15px 25px;
  /* Relleno del boton */
  position: fixed;
  bottom: 100px;
  right: 10px;
  transition: all 300ms ease 0ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.btn-flotantePrincipal:hover {
  background-color: #000;
  /* Color de fondo al pasar el cursor */
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px);
}

/* Estilos track de scroll */

::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.modalLogin {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.fill {
  -o-object-fit: fill;
  object-fit: fill;
}

.contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.none {
  -o-object-fit: none;
  object-fit: none;
}

.scale-down {
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.resize_fit_center {
  max-width: 60%;
  max-height: 60%;
  vertical-align: middle;
}

.contenedor {
  margin: 10px;
  width: 115px;
  height: 115px;
  line-height: 115px;
  text-align: center;
  border: 1px solid red;
}

select option:disabled {
  color: #a7abad;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.wrapper {
  padding: 11px;
}

.wrapper select.form-control {
  &:focus {
    border: none;
  }
  &:active {
    border: none;
  }
}

.wrapper label.floating-label {
  display: block;
  position: relative;

  text-align: center;
}

.wrapper label.floating-label input {
  top: 12px;
  font-size: 1em;
  height: 2.2em;
  padding: 5px;
  line-height: 1.5;
  color: #888888;
  background-clip: padding-box;
  &:focus {
    border: none;
  }

  &:active {
    border: none;
  }
}

.wrapper label.floating-label input:focus {
  color: #888888;
  outline: 0;
}

div.muni {
  max-height: 250px;
  width: 100%;
  position: absolute;
  z-index: 1000000000000;
  background: white;
  overflow: auto;
}

div.muniperf {
  max-height: 250px;
  width: 100%;
  position: absolute !important;
  z-index: 1000000000000 !important;
  overflow: auto !important;
  background-color: white;
}

div.munireg {
  max-height: 250px;
  width: 100%;
  position: absolute !important;
  z-index: 1000000000000 !important;
  overflow: auto !important;
  background-color: white;
}

label.floating-label input + span {
  position: absolute;
  top: 0em;
  left: 0;
  display: block;
  width: 100%;
  font-size: 0.9em;
  line-height: 1.5;
  color: #888888;
  transform: translateY(-20px);
  transition:
    font-size 0.1s ease-in-out,
    top 0.1s ease-in-out;
}

.label_color{
  color: #2c3e50;
}

.wrapper strong.str {
  position: absolute;
  transform: translateY(-40px);
  transition:
    font-size 0.1s ease-in-out,
    top 0.1s ease-in-out;
}

.txt_error{
  color: red;
  font-weight: 400;
}

h6.error {
  color: rgba(255, 100, 100, 0.664);
  transform: translateY(20px);
  text-shadow: 0 1px 0 rgb(207, 207, 207);
}

h6.error {
  color: rgba(255, 100, 100, 0.664);
  transform: translateY(20px);
}

.error_container{
  border: 1px solid red;
  border-radius: 4px;
}

.text-right {
  text-align: start !important;
}

label.floating-label input:placeholder-shown {
  padding-top: 0;
  font-size: 1em;
  padding: 2px;
}

label.floating-label input:placeholder-shown + span {
  top: 1.5em;
  font-size: 1em;
}

.output {
  width: 100%;
  margin-left: 50%;
}

input[type="range"] {
  appearance: none;
  /* Hides the slider so that custom slider can be made */
  height: 5px;
  width: 100%;
  cursor: pointer;
  background: rgb(0, 204, 177);
  margin-bottom: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  border: 2px solid rgb(0, 204, 177);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: #fff;
  cursor: pointer;
  margin-top: 0px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

input[type="range"]:focus {
  outline: none;
}

.imgs {
  margin-bottom: 50px;
}

.contenedores {
  text-align: center;
}

.mk {
  width: 120px;
  height: 120px;
  margin: 20px 20px;
  -o-object-fit: cover;
  object-fit: cover;
}

.mk2 {
  width: 200px;
  height: 200px;
  margin: 20px 20px;
  -o-object-fit: cover;
  object-fit: cover;
}

.mk2:hover {
  width: auto !important;
  height: auto !important;
  transition-duration: 5s !important;
}

/* // IMAGENES SERVICIOS O PUBLICACIONES  */

.mk3 {
  width: 80%;
  height: 300px;
  -o-object-fit: contain;
  object-fit: contain;
}

/* // BOTONES DE IMAGENES SERVICIOS O PUBLICACIONES */

.carruselBtn {
  width: 20px;
  height: 60px;
  margin: 120px -2%;
  background-color: #555555;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  -o-object-fit: contain;
  object-fit: contain;
}

/* // ALBUMES SERVICIOS O PUBLICACIONES */

.mk5 {
  width: 150px;
  height: 150px;
  margin: 0px 14px;
  -o-object-fit: contain;
  object-fit: contain;
}

/* // BOTONES DE IMAGENES SERVICIOS O PUBLICACIONES */

.indicators {
  width: 18px;
  height: 50px;
  background-color: #67757c;
  margin-top: 45px;
}

.mk7 {
  width: 320px;
  height: 320px;
  -o-object-fit: auto;
  object-fit: auto;
}

/*MODAL CON IMAGENES Y RECORTES*/

.img-container {
  width: 400px;
  height: 300px;
  float: left;
  -o-object-fit: auto;
  object-fit: auto;
  background-color: rgba(0, 0, 0, 0);
}

.img-preview {
  width: 300px;
  height: 250px;
  float: left;
  margin-left: 10px;
  margin-bottom: 10px;
}

.img-modal-imagen {
  width: 450px;
  height: 450px;
  -o-object-fit: contain;
  object-fit: contain;
}

.cropper {
  width: 450px;
  background-color: rgba(0, 0, 0, 0);
}

.img-modal-recorte {
  width: 400px;
  height: 350px;
  -o-object-fit: contain;
  object-fit: contain;
}

/*MODAL CON IMAGENES*/

.mk8 {
  width: 500px;
  height: 450px;
  -o-object-fit: contain;
  object-fit: contain;
}

/*BOTONES DE MODAL CON IMAGENES*/

.mk9 {
  width: 40px;
  height: 70px;
  margin: 250px 0px;
  color: white;
  background-color: #555555;
}

/* // ALBUMES SERVICIOS O PUBLICACIONES */

.perfilAlbumes {
  width: 120px;
  height: 120px;
  margin: 0px 14px;
  -o-object-fit: contain;
  object-fit: contain;
}

/* // BOTONES DE IMAGENES SERVICIOS O PUBLICACIONES */

.perfilAlbumesBotones {
  width: 18px;
  height: 120px;
  background-color: #555555;
}

/* CHIPS STYLE */

.chip {
  display: inline-block;
  padding: 0 20px;
  margin: 4px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  border-radius: 25px;
  background-color: #f1f1f1;
}

/* // BTN CHIPS */

.closebtn {
  padding-left: 10px;
  color: #888;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}

/* BTN CHIPS */

.closebtn:hover {
  color: #000;
}

.panel {
  font-size: 1.3em;
  position: relative;
  opacity: 100%;
  top: 50%;
  left: 50%;
  right: 50%;
  border-radius: 4px;
}

.modalFotos.modal {
  /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.659);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  /* Fallback color */
  overflow: hidden;
  /* Black w/ opacity */
}

.modalLanding.modal {
  /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.659);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  /* Fallback color */
  /* Black w/ opacity */
}

.modalCitas.modal {
  /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.788);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  /* Fallback color */
  /* Black w/ opacity */
}

.modal-content {
  border: none;
  -webkit-box-shadow: 0px 0px 60px 8px rgba(0,0,0,0.6);
  -moz-box-shadow: 0px 0px 60px 8px rgba(0,0,0,0.6);
  box-shadow: 0px 0px 60px 8px rgba(0,0,0,0.6);

}

.modal_content_90 {
  border: none;
  // width: fit-content !important;
  min-height: 90vh;
}

.modal-content-adjust {
  display: contents !important;
  height: fit-content !important;
  width: fit-content !important;
}

.heading {
  font-size: 25px;
  margin-right: 25px;
}

.checked {
  color: orange;
}

/* Three column layout */

.side {
  float: left;
  width: 15%;
  margin-top: 10px;
}

.middle {
  margin-top: 10px;
  float: left;
  width: 70%;
}

/* Place text to the right */

.right {
  text-align: right;
}

/* Clear floats after the columns */

.row:after {
  content: "";
  display: table;
  clear: both;
}

/* The bar container */

.bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
}

/* Individual bars */

.bar-5 {
  width: 60%;
  height: 18px;
  background-color: #4caf50;
}

.bar-4 {
  width: 30%;
  height: 18px;
  background-color: #2196f3;
}

.bar-3 {
  width: 10%;
  height: 18px;
  background-color: #00bcd4;
}

.bar-2 {
  width: 100%;
  height: 18px;
  background-color: #ff9800;
}

.bar-1 {
  width: 15%;
  height: 18px;
  background-color: #f44336;
}

.cursor-pointer {
  cursor: pointer;
}

.cal-day-selected,
.cal-day-selected:hover {
  background-color: deeppink !important;
}

.my-fixed-item {
  position: fixed;
  width: 35%;
  height: 100%;
  margin-right: 3%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.my-fixed-item-reg {
  position: fixed;
  width: 35%;
  height: 100%;
  border-radius: 20px;
  margin-right: 3%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.my-fixed-item-nav {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.my-fixed-item-navs {
  width: 50%;
  height: 100%;
  border-radius: 20px;
  margin-left: 6%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.my-fixed-item-nav-info {
  width: 80%;
  height: 80%;
  border-radius: 20px;
  margin-left: 10%;
  margin-right: 10%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.divis {
  margin-left: 5%;
  width: 90%;
  height: 90%;
  overflow: scroll;
}

.loginDiv {
  margin-left: 5%;
  width: 92%;
}

.placeholderss::-webkit-input-placeholder {
  color: gray;
  font-size: 1em;
}

.placeholderss::-moz-placeholder {
  color: gray;
  font-size: 1em;
}

.placeholderss:-ms-input-placeholder {
  color: gray;
  font-size: 1em;
}

.placeholderss::-ms-input-placeholder {
  color: gray;
  font-size: 1em;
}

.placeholderss::placeholder {
  color: gray;
  font-size: 1em;
}

/*AYUDAS*/

.modalBackgrounds.modal {
  background-color: rgba(0, 0, 0, 0) !important;
}

.modalSelectServ {
  height: 100%;
  width: 100%;
  margin-top: 200px;
  margin-left: 150px;
}

.modalSelectFil {
  height: 100%;
  width: 100%;
  margin-top: 200px;
  margin-left: 35%;
}

.modalSelectMed {
  height: 100%;
  width: 100%;
  margin-top: 200px;
  margin-right: 20px;
}

.blurTerminos {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.deshabilitado {
  display: none;
}

.snap-container {
  height: 100vh;
  overflow: auto;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
}

.snap-section {
  height: 100vh;
  width: 101%;
  text-align: center;
  padding: 0 0 0 0;
  scroll-snap-align: start;
  min-height: 70%;
}

#helpIcon {
  cursor: pointer;
  padding-top: 12px;
}

/*ENCABEZADOS DE TRABLAS REDONDEADOS*/

thead th:first-child {
  border-radius: 15px 0 0 0;
}

thead th:last-child {
  border-radius: 0 15px 0 0;
}

.portada {
  height: 150px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  z-index: -100000 !important;
}

.portadaRegistro {
  height: 150px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  z-index: -100000 !important;
}

.perfil {
  position: absolute;
  height: 120px;
  width: 120px;
  border: 2px solid #fff;
  top: 16%;
  -o-object-fit: contain;
  object-fit: contain;
  background-color: #000;
  z-index: 1 !important;
}

.perfilRegistro {
  position: absolute;
  height: 120px;
  width: 120px;
  border: 2px solid #fff;
  top: 60%;
  -o-object-fit: contain;
  object-fit: contain;
  background-color: #000;
  z-index: 1 !important;
}

.perfilGlobal {
  width: 90px;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid #fff;
  background-color: #000;
}

.contenedorPerfil {
  display: flex;
  justify-content: center;
}

.botonCambioPerfil {
  position: absolute;
  top: 25%;
  margin-right: -25%;
  z-index: 2 !important;
  background: #44444480;
  color: rgba(236, 240, 241, 0.5);
}

.botonCambioPortada {
  position: absolute;
  top: 7%;
  right: 10%;
  z-index: 2 !important;
  background: #44444480;
  color: rgba(236, 240, 241, 0.5);
}

.botonCambioPortadaRegistro {
  position: absolute;
  top: 65%;
  right: 10%;
  z-index: 2 !important;
  background: #44444480;
  color: rgba(236, 240, 241, 0.5);
}

.botonCambioPerfilRegistro {
  position: absolute;
  top: 90%;
  margin-right: -20%;
  z-index: 2 !important;
  background: #44444480;
  color: rgba(236, 240, 241, 0.5);
}

div.rips {
  max-height: 250px;
  width: 100%;
  /* position: absolute !important; */
  z-index: 1000000000000 !important;
  overflow: auto !important;
  background-color: white;
}

.label_active {
  background-color: var(--primary);
}
.label_inactive {
  background-color: var(--inactive);
}

.label_blue {
  background-color: var(--blue);
}

.label_warning {
  background-color: var(--warning);
}

.label_danger {
  background-color: var(--danger);
}

select {
  appearance: none;
  text-align-last: center;
}

.cardsList {
  border: solid 1px #4c99ff8f;
  border-radius: 20px;
  padding: 20px;
}

.texto-primary {
  color: var(--primary);
}
.texto-primary2 {
  color: var(--primary_2);
}

.fondo-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.fondo-gradient-primary {
  background: linear-gradient(to bottom right, var(--primary), var(--primary_2));
  border-color: var(--primary);
}

.fondo-gradient-primary:hover {
  background: linear-gradient(to bottom right, #43ab8c, #12523e);
}

.logo_size {
  height: 40px;
}

.texto_size {
  height: 35px;
}

.btn_back {
  background-color: #ffffff;
}

.btn_back_transparent{
  background-color: #ffffff67;

}

.btn_back_transparent:hover{
  background-color: #ffffffaa;

}

.btn_back:hover {
  background-color: #f5f5f5;
}

.btn_back_2 {
  background-color: rgb(32, 33, 36);
  color: #fff;
}

.btn_back_2:hover {
  background-color: rgb(73, 76, 84);
}

.i_90 {
  transform: rotate(90deg) !important;
}

.fw_300 {
  font-weight: 300 !important;
}

.fw_400 {
  font-weight: 400 !important;
}

.fw_500 {
  font-weight: 500 !important;
}

.fw_600 {
  font-weight: 600 !important;
}

.fw_700 {
  font-weight: 700 !important;
}

.fz_12{
  font-size: 12px !important;
}
.fz_13{
  font-size: 13px !important;
}
.fz_14{
  font-size: 14px !important;
}

.fz_15{
  font-size: 15px !important;
}

.fz_16{
  font-size: 16px !important;
}

.fz_17{
  font-size: 17px !important;
}

.fz_18{
  font-size: 18px !important;
}
.fz_19{
  font-size: 19px !important;
}

.fz_20{
  font-size: 20px !important;
}
.fz_21{
  font-size: 21px !important;
}
.fz_22{
  font-size: 22px !important;
}
.fz_23{
  font-size: 23px !important;
}
.fz_24{
  font-size: 24px !important;
}
.fz_25{
  font-size: 25px !important;
}

.fz_1rem{
  font-size: 1rem !important;
}

/*
Tamaño por defecto de los iconos
*/

.fa {
  font-size: 15px;
}

.mdi {
  font-size: 22px;
}
/*
Tamaño por defecto de los iconos
*/

.color_black{
  color: black;
}

//space rigth
.spr_5{
  margin-right: 5px;
}

.spl_5{
  margin-left: 5px;
}


.sort_container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}

.sort_icon_container{
  position: relative;
  top: -7px;
  right: -7px;
}

.sort_icon{
  position: absolute;
  cursor: pointer;
}

.sort_icon_default{
  color: #80808075;
}

.align_center{
  display: flex;
  justify-content: center;
}

.chip_item {
  background: var(--primary_70);
    color: var(--light);
    border-radius: 4px;
    padding: 2px 8px;
    font-weight: 500;
    font-size: 0.8em;
    margin: 2px;
}

.demo_input_search2 {
  border: 1px solid rgba(128, 128, 128, 0.4901960784);
  padding: 2px 2px 2px 15px;
  border-radius: 4px;
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.2509803922) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3019607843) 0px 3px 7px -3px;
  }
}

.si_registrados{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #deffd7;
  min-height: 70px;
  border-radius: 4px !important;
  padding: 15px;
}

.no_registrados{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #deeaf2;
  min-height: 70px;
  border-radius: 4px !important;
  padding: 15px;
}

.text_ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.box_shadow{
  box-shadow: 4px 4px 9px rgba(64, 64, 64, 0.56);
}

.br_2{
  border-radius: 2px;
}

.br_4{
  border-radius: 4px;
}

.ellipsis__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
}


.icon_options_adjust{
  top:10px !important;
  left: 15px !important;
  &::before{
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: white;
    position: absolute;
    top: -6px;
    right: 20px;
    z-index: -30 !important;
    border-radius: 4px;
    transform: rotate(45deg);
  }
}

.modal{
  z-index: 100000 !important;
  backdrop-filter: blur(9px);
}

.swal2-container{
  z-index: 200000 !important;
}

.modal_content{
  max-width: max-content;
}


.modal_xl{
  max-width: 950px;
}

.modal_xxl{
  max-width: 1090px;
}

.separador{
  display: inline-block;
  border-top: solid 1px #b4b4b457;
  padding: 0 5px;
  width: 100%;
}

.item_hover{
  cursor: pointer;
  &:hover{
    background-color: rgb(246, 246, 246);
  }
}

.config_title{
  font-size: 20px;
  font-weight: 500;
}

.pd_5{
  padding: 5px;
}

.pd_10{
  padding: 10px;
}

.pd_15{
  padding: 15px;
}

.pd_20{
  padding: 20px;
}

.pd_l_15{
  padding-left: 15px;
}
.pd_r_15{
  padding-right: 15px;
}

.img-logo-auth {
  width: 60%;
}

.btn_search {
  border: 1px solid var(--primary_70);
  background: var(--primary);
  color:#fff;
  border-radius: 5px;
  cursor: pointer;
  outline: none;

  &:not(:disabled):hover {
    background: var(--primary_70);
  }

  &:disabled {
    background: #7e7e7e;
    cursor: not-allowed;
    color: #aeaeae;
    border: none;
  }
}

.label_marker{
  // margin-top: 100px;
  font-weight: 400;
  background-color: #2525258c;
  padding: 5px;
  border-radius: 4px;
  color: #fff;

}

.input__label_place {
  padding: 4px 12px 4px 0;
  font-weight: 500;
  font-size: 17px;
  color: #2c3e50;
  margin: 0;
  display: flex;
  strong {
    margin-left: 5px;
    color: var(--danger);
    font-weight: bold;
  }
}

.input__label {
  padding: 4px 12px 4px 0;
  font-weight: 500;
  color: #2c3e50;
  margin: 0;

  strong {
    color: var(--danger);
    font-weight: bold;
  }
}

// .btn-circle{
//   border: 1px solid rgb(135 135 135 / 58%);
// }

.user_active{
  background-color: #00ab78;
  color:#fff;
}

.user_inactive{
  background-color: #d2e7f3;
}

.loader__icon {
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
  color: var(--primary);
  text-indent: -9999em;
  border-radius: 50%;
  position: relative;
  font-size: 3px;
  z-index: 10;
  height: 1em;
  right: 44px;
  width: 1em;
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow:
      0 -3em 0 0.2em,
      2em -2em 0 0em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 0;
  }
  12.5% {
    box-shadow:
      0 -3em 0 0,
      2em -2em 0 0.2em,
      3em 0 0 0,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  25% {
    box-shadow:
      0 -3em 0 -0.5em,
      2em -2em 0 0,
      3em 0 0 0.2em,
      2em 2em 0 0,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0em 0 0,
      2em 2em 0 0.2em,
      0 3em 0 0em,
      -2em 2em 0 -1em,
      -3em 0em 0 -1em,
      -2em -2em 0 -1em;
  }
  50% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 0em,
      0 3em 0 0.2em,
      -2em 2em 0 0,
      -3em 0em 0 -1em,
      -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 0,
      -2em 2em 0 0.2em,
      -3em 0 0 0,
      -2em -2em 0 -1em;
  }
  75% {
    box-shadow:
      0em -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0em 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0em 0 0.2em,
      -2em -2em 0 0;
  }
  87.5% {
    box-shadow:
      0em -3em 0 0,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
}

.img_cover_pet {
  height: 60px;
  border-radius: 10%;
}

.form-material .form-control, .form-material .form-control.focus, .form-material .form-control:focus{
  background-image: linear-gradient(var(--primary), var(--primary)), linear-gradient(#e9edf2, #e9edf2);
}


.align_start{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.align_end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container-input {
  align-items: flex-end !important;
}

.separator_dot {
  display: flex; /* Permite la alineación de los elementos en línea */
  flex-wrap: wrap; /* Permite que los elementos se envuelvan a la siguiente línea */
  align-items: center; /* Alinea los elementos verticalmente en el centro */
}

.separator_dot > span {
  display: inline-block; /* Hace que cada span se comporte como un bloque en línea */
}

.separator_dot > span::after {
  content: '•'; /* Carácter del punto (•) */
  color: black; /* Color rojo para el punto */
  margin: 0 0.5em; /* Espaciado horizontal alrededor del punto */
}

.separator_dot > span:last-child::after {
  content: ''; /* Elimina el punto después del último elemento */
}

/* Minimum height for table rows */
.height_row_50 {
  height: 50px; /* Adjust this value as needed */
}


// texto salto de linea a un max width
.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.txt_max_100 {
  max-width: 100px;
}
.txt_max_150 {
  max-width: 150px;
}
.txt_max_200 {
  max-width: 200px;
}

/* Modifiers for line clamp */
.line_clamp_1 {
  -webkit-line-clamp: 1;
}

.line_clamp_2 {
  -webkit-line-clamp: 2;
}

.line_clamp_3 {
  -webkit-line-clamp: 3;
}

.line_clamp_4 {
  -webkit-line-clamp: 4;
}

.size_150 {
  width: 150px;
  height: 150px;
}

.size_50 {
  width: 50px;
  height: 50px;
}

.size_30 {
  width: 30px;
  height: 30px;
}

/* Add more as needed */

.container-width{
  width: 100%;
  max-width: 1360px;
  height: 100%;
  border: 1px solid ;
  height: 100vh;
  // background-color: var(--gray);
}
